export const US_STATES_MAPPING: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const FAQ_ACCORDION_SCHEMA = [
  {
    title: 'How does car insurance comparison work?',
    content:
      'Our website collects your information, such as your car make and model, driving history, and personal details, and compares quotes from multiple insurance providers. You can choose the policy that best suits your needs and budget. It is completely free for you to use.',
  },
  {
    title: 'Can I trust the quotes provided by the website?',
    content:
      'Yes, the quotes we provide are accurate and up-to-date. We work with reputable insurance providers to ensure you get the best deals.',
  },
  {
    title: 'Can I switch my policy if I find a better deal later on?',
    content:
      'Yes, you can switch your car insurance policy at any time if you find a better deal elsewhere. However, make sure to consider any cancellation fees or penalties before making the switch.',
  },
  {
    title: 'Is my personal information safe?',
    content:
      'Yes, we take your privacy seriously and use the latest encryption technology to keep your personal information secure. We only share your information with insurance providers to provide you with accurate quotes.',
  },
  {
    title:
      'What if I have more questions or need help with my car insurance comparison?',
    content:
      "Our team is always available to answer your questions and help you with your car insurance comparison. You can reach out to us through our contact page, and we'll be happy to assist you.",
  },
];
