import { type FC, type ReactNode } from 'react';
import cn from 'classnames';
import s from './style.module.scss';

type TProps = {
  className?: string;
  children: ReactNode;
};

export const Highlight: FC<TProps> = ({ className, children }) => (
  <span className={cn(className, s.highlight)}>
    {children}
    <svg
      className={s.highlightSvg}
      viewBox="0 0 175 24"
      preserveAspectRatio="xMidYMid slice"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.307632 24C58.3396 20.0922 116.556 17.5743 174.835 16.4503C175.224 16.4425 174.799 0.000780006 174.835 0C116.556 1.12399 58.3396 3.64185 0.307632 7.54968C-0.611528 7.6113 0.877645 23.9618 0.307632 24Z"
        fill="currentColor"
      />
    </svg>
  </span>
);
