import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { Icon } from '@/components/common/icon';

import s from './style.module.scss';

export type TModalProps = {
  className?: string;
  children?: React.ReactNode;
  isShown?: boolean;
  title?: string;
  onClose?: () => void;
  actions?: Array<{
    key: string;
    isPrimary: boolean;
    label: React.ReactNode;
    onClick: () => void;
  }>;
  template?: React.ReactNode;
};

const Modal = ({
  className,
  children,
  isShown,
  onClose,
  template,
}: TModalProps) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  const defaultTemplate = (
    <div className={cn(className, s.root, { [s.isShown]: isShown })}>
      <div className={s.card}>
        {children}

        <button className={s.closeButton} type="button" onClick={onClose}>
          <Icon className={s.closeButtonIcon} name="x" />
        </button>
      </div>
    </div>
  );

  return mounted
    ? createPortal(template || defaultTemplate, document.body)
    : null;
};

export default Modal;
