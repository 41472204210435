import React, { type FC } from 'react';
import cn from 'classnames';
import { Section } from '@/components/lumixal-landing/common/section';
import { Container } from '@/components/lumixal-landing/common/container';
import * as Accordion from '@radix-ui/react-accordion';
import { Minus, Plus } from 'phosphor-react';

import { FAQ_ACCORDION_SCHEMA } from '@/constants';

import s from './style.module.scss';

const AccordionItem = ({ trigger = '', content = '', value = '' }) => (
  <Accordion.Item className={s.accordionItem} value={value}>
    <Accordion.Trigger className={s.accordionTrigger}>
      <div className={s.accordionTriggerIconFrame}>
        <Plus
          className={cn(s.accordionTriggerIcon, s.accordionTriggerIcon_closed)}
        />
        <Minus
          className={cn(s.accordionTriggerIcon, s.accordionTriggerIcon_open)}
        />
      </div>

      <div className={s.accordionTriggerText}>{trigger}</div>
    </Accordion.Trigger>

    <Accordion.Content className={s.accordionContentFrame}>
      <div className={s.accordionContent}>{content}</div>
    </Accordion.Content>
  </Accordion.Item>
);

export const SectionFAQ: FC = () => (
  <Section className={s.root}>
    <Container className={s.container}>
      <h2 className={s.title}>
        Top 5 questions
        <br />
        people ask us
      </h2>

      <Accordion.Root className={s.accordion} type="single" collapsible>
        {FAQ_ACCORDION_SCHEMA.map((entry, index) => (
          <AccordionItem
            key={entry.title}
            value={index.toString()}
            trigger={entry.title}
            content={entry.content}
          />
        ))}
      </Accordion.Root>
    </Container>
  </Section>
);
