import { type FC, type ReactNode } from 'react';
import cn from 'classnames';
import s from './style.module.scss';

type TProps = {
  className?: string;
  children: ReactNode;
};

export const Container: FC<TProps> = ({ className, children }) => (
  <div className={cn(className, s.container)}>{children}</div>
);
