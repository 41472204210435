import { type ChangeEvent, type FC, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { MapPin, PhoneCall } from 'phosphor-react';
import cn from 'classnames';
import { getUrlParam, gtag } from '@insurance/utils';
import { AnalyticsGoals } from '@insurance/form-api';
import { formApi } from '@/services/form-api';
import { useVisibility } from '@/hooks/use-visibility';
import { LuximalLogo } from '@/components/lumixal-landing/common/logo';
import { Section } from '@/components/lumixal-landing/common/section';
import { Container } from '@/components/lumixal-landing/common/container';
import { Input } from '@/components/lumixal-landing/common/input';
import { HeroImage } from '@/components/lumixal-landing/sections/hero/hero-img';
import { Button } from '@/components/lumixal-landing/common/button';
import { useAppConfig } from '@insurance/app-config';
import s from './style.module.scss';

const prepareFormValues = (zipCode: string) => ({
  ...(zipCode ? { zipCode } : {}),
});

type TProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  buttonText: React.ReactNode;
  onGetQuotes(values: { zipCode?: string }): void;
};

export const SectionHero: FC<TProps> = ({
  title,
  subtitle,
  buttonText,
  onGetQuotes,
}) => {
  const { contactPhone, host } = useAppConfig();

  const isMobile = useMedia('(max-width: 767px)', false);

  const [mainFormRef, isMainFormVisible] = useVisibility();

  const [zipCodeValue, setZipCodeValue] = useState('');

  const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZipCodeValue(event.target.value);

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingFieldIsFilled,
      payload: {
        field: 'zip',
        value: event.target.value,
      },
    });
  };

  const handleClickGetQuotesTopButton = () => {
    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingGoToForm,
      payload: {
        referrer: 'getMyQuotes',
      },
    });

    onGetQuotes(prepareFormValues(zipCodeValue));
  };

  const handleClickGetQuotesBottomButton = () => {
    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingClickButton,
      payload: {
        buttonType: 'bottomGetQuotes',
      },
    });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingGoToForm,
      payload: {
        referrer: 'bottomGetQuotes',
      },
    });

    onGetQuotes(prepareFormValues(zipCodeValue));
  };

  const handleClickCall = () => {
    gtag({ event: 'click_to_call' });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingClickCall,
      payload: {
        referrer: 'phoneButton',
      },
    });
  };

  useEffect(() => {
    const zipCode = getUrlParam('zipCode');

    if (!zipCode) {
      return;
    }

    setZipCodeValue(zipCode);
  }, []);

  return (
    <>
      <div className={cn(s.floatedForm, { [s.isShown]: !isMainFormVisible })}>
        <label className={s.floatedFormLabel} htmlFor="fl-zip-code">
          ZIP
        </label>

        <Input
          className={s.floatedFormInput}
          size={isMobile ? 'lg' : 'xl'}
          type="tel"
          placeholder="90210"
          id="fl-zip-code"
          maxLength={5}
          value={zipCodeValue}
          onChange={handleZipCodeChange}
        />

        <Button
          className={s.floatedFormButton}
          size={isMobile ? 'lg' : 'xl'}
          variant="filled"
          color="neutral"
          onClick={handleClickGetQuotesBottomButton}
        >
          {buttonText}
        </Button>
      </div>

      <Section className={s.root}>
        <Container className={s.container}>
          <div className={s.topBar}>
            <LuximalLogo landingType="luximal" host={host} />

            <Button
              className={s.topBarButton}
              IconStart={PhoneCall}
              variant={isMobile ? 'tinted' : 'filled'}
              size={isMobile ? 'md' : 'lg'}
              href={contactPhone.href}
              onClick={handleClickCall}
            >
              {contactPhone.label}
            </Button>
          </div>

          <h1 className={s.title}>{title}</h1>

          <div className={s.cta}>
            <MapPin className={s.ctaIcon} />

            <span className={s.ctaText}>{subtitle}</span>
          </div>

          <div className={s.form} ref={mainFormRef}>
            <div className={s.ctaPointer} />

            <Input
              className={s.formInput}
              size={isMobile ? 'lg' : 'xl'}
              type="tel"
              placeholder="90210"
              maxLength={5}
              value={zipCodeValue}
              onChange={handleZipCodeChange}
            />

            <Button
              className={s.formButton}
              size={isMobile ? 'lg' : 'xl'}
              variant="filled"
              color="neutral"
              onClick={handleClickGetQuotesTopButton}
            >
              {buttonText}
            </Button>
          </div>

          <div className={s.imgFrame}>
            <HeroImage className={s.img} />
          </div>
        </Container>
      </Section>
    </>
  );
};
