import { type ChangeEvent, forwardRef, type InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { type Icon } from 'phosphor-react';
import s from './style.module.scss';

type TInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  className?: string;
  size?: 'md' | 'lg' | 'xl';
  variant?: 'tinted';
  IconStart?: Icon;
  IconEnd?: Icon;
  isError?: boolean;
  isSuccess?: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      className,
      size = 'md',
      variant = 'tinted',
      IconStart,
      IconEnd,
      isError,
      isSuccess,
      value,
      onChange,
      ...props
    },
    ref,
  ) => (
    <div
      className={cn(
        className,
        s.input,
        s[`size_${size}`],
        s[`variant_${variant}`],
        { [s.isError]: isError },
        { [s.isSuccess]: isSuccess },
      )}
    >
      {IconStart && <IconStart className={cn(s.icon, s.icon_start)} />}

      <input
        ref={ref}
        className={s.htmlInput}
        type="text"
        value={value}
        onChange={onChange}
        {...props}
      />

      {IconEnd && <IconEnd className={cn(s.icon, s.icon_end)} />}
    </div>
  ),
);

Input.displayName = 'Input';
