import { type FC, type ReactElement } from 'react';
import cn from 'classnames';
import { type Icon, Shield, Umbrella, Lifebuoy } from 'phosphor-react';
import s from './style.module.scss';

type TItemProps = {
  Icon: Icon;
  Skeleton: ReactElement;
  price: number;
};

type THeroImageProps = {
  className?: string;
};

const HeroImageItem: FC<TItemProps> = ({ Icon, Skeleton, price }) => (
  <div className={s.item}>
    <div className={s.itemIconFrame}>
      <Icon className={s.itemIcon} />
    </div>

    {Skeleton}

    <div className={s.itemPrice}>
      <span className={s.itemPriceVal}>${price}</span> / mo
    </div>
  </div>
);

export const HeroImage: FC<THeroImageProps> = ({ className }) => (
  <div className={cn(className, s.image)}>
    <div className={s.itemGroup}>
      <HeroImageItem
        Icon={Shield}
        price={29}
        Skeleton={
          <svg
            className={s.itemSkeleton}
            width="72"
            height="26"
            viewBox="0 0 72 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 2C0 0.89543 0.895431 0 2 0H54C55.1046 0 56 0.895431 56 2V4C56 5.10457 55.1046 6 54 6H2C0.89543 6 0 5.10457 0 4V2Z"
              fill="#CCCCCC"
            />
            <path
              d="M0 12C0 10.8954 0.895431 10 2 10H70C71.1046 10 72 10.8954 72 12V14C72 15.1046 71.1046 16 70 16H2C0.895431 16 0 15.1046 0 14V12Z"
              fill="#CCCCCC"
            />
            <path
              d="M0 22C0 20.8954 0.895431 20 2 20H30C31.1046 20 32 20.8954 32 22V24C32 25.1046 31.1046 26 30 26H2C0.895431 26 0 25.1046 0 24V22Z"
              fill="#CCCCCC"
            />
          </svg>
        }
      />

      <HeroImageItem
        Icon={Umbrella}
        price={35}
        Skeleton={
          <svg
            className={s.itemSkeleton}
            width="80"
            height="26"
            viewBox="0 0 80 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 2C0 0.89543 0.895431 0 2 0H46C47.1046 0 48 0.895431 48 2V4C48 5.10457 47.1046 6 46 6H2C0.895431 6 0 5.10457 0 4V2Z"
              fill="#CCCCCC"
            />
            <path
              d="M0 12C0 10.8954 0.895431 10 2 10H78C79.1046 10 80 10.8954 80 12V14C80 15.1046 79.1046 16 78 16H2C0.895429 16 0 15.1046 0 14V12Z"
              fill="#CCCCCC"
            />
            <path
              d="M0 22C0 20.8954 0.895431 20 2 20H14C15.1046 20 16 20.8954 16 22V24C16 25.1046 15.1046 26 14 26H2C0.895431 26 0 25.1046 0 24V22Z"
              fill="#CCCCCC"
            />
            <path
              d="M20 22C20 20.8954 20.8954 20 22 20H50C51.1046 20 52 20.8954 52 22V24C52 25.1046 51.1046 26 50 26H22C20.8954 26 20 25.1046 20 24V22Z"
              fill="#CCCCCC"
            />
          </svg>
        }
      />

      <HeroImageItem
        Icon={Lifebuoy}
        price={42}
        Skeleton={
          <svg
            className={s.itemSkeleton}
            width="68"
            height="26"
            viewBox="0 0 68 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 2C0 0.89543 0.895431 0 2 0H62C63.1046 0 64 0.895431 64 2V4C64 5.10457 63.1046 6 62 6H2C0.895431 6 0 5.10457 0 4V2Z"
              fill="#CCCCCC"
            />
            <path
              d="M0 12C0 10.8954 0.895431 10 2 10H46C47.1046 10 48 10.8954 48 12V14C48 15.1046 47.1046 16 46 16H2C0.895431 16 0 15.1046 0 14V12Z"
              fill="#CCCCCC"
            />
            <path
              d="M52 12C52 10.8954 52.8954 10 54 10H66C67.1046 10 68 10.8954 68 12V14C68 15.1046 67.1046 16 66 16H54C52.8954 16 52 15.1046 52 14V12Z"
              fill="#CCCCCC"
            />
            <path
              d="M0 22C0 20.8954 0.895431 20 2 20H38C39.1046 20 40 20.8954 40 22V24C40 25.1046 39.1046 26 38 26H2C0.895431 26 0 25.1046 0 24V22Z"
              fill="#CCCCCC"
            />
          </svg>
        }
      />
    </div>

    <div className={s.deviceFrame}>
      <svg
        className={cn(s.device, s.device_mobile)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 648"
      >
        <rect
          id="bg"
          fill="currentColor"
          x="9.67"
          y="7.32"
          width="299.66"
          height="633.94"
          rx="41.29"
          ry="41.29"
        />
        <image
          id="img"
          width="480"
          height="972"
          transform="scale(.67)"
          href="/images/lumixal/mobile.png"
        />
      </svg>

      <svg
        className={cn(s.device, s.device_laptop)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 456 287"
      >
        <rect
          id="bg"
          fill="currentColor"
          x="39.97"
          y="5.88"
          width="376.68"
          height="246.29"
        />
        <image
          id="img"
          width="456"
          height="287"
          href="/images/lumixal/laptop.png"
        />
      </svg>
    </div>
  </div>
);
