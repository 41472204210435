import { type FC } from 'react';
import cn from 'classnames';
import { useContactEmail } from '@/hooks/use-contact-email';
import { Section } from '@/components/lumixal-landing/common/section';
import { Container } from '@/components/lumixal-landing/common/container';

import s from './style.module.scss';

type TProps = {
  onContactClick?(): void;
};

export const SectionFooter: FC<TProps> = ({ onContactClick }) => {
  const contactEmail = useContactEmail();

  return (
    <Section className={s.root}>
      <Container className={s.container}>
        <div className={s.linkGroup}>
          <a className={s.link} href="/page/privacy-policy" target="_blank">
            Privacy Policy
          </a>

          <a className={s.link} href="/page/terms-of-use" target="_blank">
            Terms Of Use
          </a>

          <a className={s.link} href="/page/cookies-policy" target="_blank">
            Cookies Policy
          </a>

          <button
            type="button"
            className={s.contactBtn}
            onClick={onContactClick}
          >
            Contact
          </button>

          <a
            className={s.link}
            href="/landing-auth/unsubscribe"
            target="_blank"
          >
            Unsubscribe
          </a>
        </div>

        <div className={s.textBlock}>
          <div className={s.textBlockTitle}>Customer Service</div>
          <div className={s.textBlockContent}>{contactEmail}</div>
        </div>

        <div className={cn(s.textBlock, s.textBlock_disclaimer)}>
          <div className={s.textBlockContent}>
            We make every effort to ensure the accuracy and timeliness of the
            information we provide. However, the information you see when you
            visit a financial institution, service provider, or product site may
            differ from ours. We present all financial products, shopping
            products, and services without warranty. It’s important to review
            the terms and conditions of any financial institution before making
            a decision. Please note that pre-qualified offers are not legally
            binding.
          </div>
        </div>
      </Container>
    </Section>
  );
};
