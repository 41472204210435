import React, { type FC } from 'react';
import Modal, {
  type TModalProps,
} from '@/components/lumixal-landing/common/modal';
import { Button } from '@/components/lumixal-landing/common/button';
import { Highlight } from '@/components/lumixal-landing/common/highlight';
import s from './style.module.scss';

type TModalSwitchProviderProps = Omit<TModalProps, 'actions'> & {
  onActionClick: () => void;
};

export const ModalSwitchProvider: FC<TModalSwitchProviderProps> = ({
  onActionClick,
  ...props
}) => (
  <Modal {...props}>
    <div className={s.root}>
      <svg
        className={s.switch}
        width="128"
        height="72"
        viewBox="0 0 128 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
      >
        <g filter="url(#filter0_i_69_1394)">
          <rect
            y="0.289551"
            width="128"
            height="72"
            rx="36"
            fill="currentColor"
          />
          <rect
            y="0.289551"
            width="128"
            height="72"
            rx="36"
            fill="url(#paint0_linear_69_1394)"
            fillOpacity="0.1"
          />
        </g>
        <g className={s.switchToggle}>
          <g filter="url(#filter1_d_69_1394)">
            <circle cx="92" cy="36" r="32" fill="url(#paint1_linear_69_1394)" />
            <circle
              cx="92"
              cy="36"
              r="31.1111"
              stroke="white"
              strokeWidth="1.77778"
            />
          </g>
          <g filter="url(#filter2_di_69_1394)">
            <path
              d="M92.2188 53.7104C91.8988 53.7104 91.5788 53.6409 91.2866 53.4878C90.7856 53.2374 79 47.2402 79 37.7087V27.9685C79 27.0919 79.5427 26.3127 80.3497 26.0205L91.4814 21.8461C91.9545 21.6652 92.4693 21.6652 92.9424 21.8461L104.074 26.0205C104.895 26.3266 105.424 27.1058 105.424 27.9685V37.7087C105.424 47.2402 93.6381 53.2374 93.1372 53.4878C92.845 53.6409 92.525 53.7104 92.2049 53.7104H92.2188ZM83.1744 29.4156V37.7087C83.1744 42.9267 89.0185 47.3932 92.2049 49.2439C94.6817 47.7828 101.263 43.3859 101.263 37.7087V29.4156L92.2188 26.0205L83.1744 29.4156Z"
              fill="currentColor"
            />
            <path
              d="M92.2188 53.7104C91.8988 53.7104 91.5788 53.6409 91.2866 53.4878C90.7856 53.2374 79 47.2402 79 37.7087V27.9685C79 27.0919 79.5427 26.3127 80.3497 26.0205L91.4814 21.8461C91.9545 21.6652 92.4693 21.6652 92.9424 21.8461L104.074 26.0205C104.895 26.3266 105.424 27.1058 105.424 27.9685V37.7087C105.424 47.2402 93.6381 53.2374 93.1372 53.4878C92.845 53.6409 92.525 53.7104 92.2049 53.7104H92.2188ZM83.1744 29.4156V37.7087C83.1744 42.9267 89.0185 47.3932 92.2049 49.2439C94.6817 47.7828 101.263 43.3859 101.263 37.7087V29.4156L92.2188 26.0205L83.1744 29.4156Z"
              fill="black"
              fillOpacity="0.03"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_i_69_1394"
            x="0"
            y="0.289551"
            width="128"
            height="75.8"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3.8" />
            <feGaussianBlur stdDeviation="1.9" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_69_1394"
            />
          </filter>
          <filter
            id="filter1_d_69_1394"
            x="52.8889"
            y="4"
            width="78.2222"
            height="78.2222"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="7.11111" />
            <feGaussianBlur stdDeviation="3.55556" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_69_1394"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_69_1394"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_di_69_1394"
            x="79"
            y="21.7104"
            width="26.4238"
            height="35.5556"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.888889" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_69_1394"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_69_1394"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3.55556" />
            <feGaussianBlur stdDeviation="1.77778" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_69_1394"
            />
          </filter>
          <linearGradient
            id="paint0_linear_69_1394"
            x1="64"
            y1="0.289551"
            x2="64"
            y2="72.2896"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_69_1394"
            x1="92"
            y1="4"
            x2="92"
            y2="68"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E6E6E6" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>

      <div className={s.title}>
        Already insured?
        <br /> We&apos;ll help you <Highlight>switch!</Highlight>
      </div>

      <Button
        className={s.button}
        variant="filled"
        size="xl"
        onClick={onActionClick}
      >
        Let’s switch!
      </Button>
    </div>
  </Modal>
);
