import { type FC, type ReactNode } from 'react';
import cn from 'classnames';
import s from './style.module.scss';

type TProps = {
  className?: string;
  children: ReactNode;
};

export const Section: FC<TProps> = ({ className, children }) => (
  <section className={cn(className, s.section)}>{children}</section>
);
