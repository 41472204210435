import { InsuranceHost } from '@insurance/utils';
import { useAppConfig } from '@insurance/app-config';

export const usePageTitle = (): string => {
  const { host } = useAppConfig();

  switch (host) {
    case InsuranceHost.Requote:
      return 'Compare and Save on Car Insurance | Requote';
    default:
      return 'Compare car insurance rates';
  }
};
