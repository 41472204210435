import { useEffect } from 'react';
import cn from 'classnames';
import { getUrlWithParams, gtag } from '@insurance/utils';
import { AnalyticsGoals } from '@insurance/form-api';
import { outfit } from '@/styles/fonts';
import { formApi } from '@/services/form-api';
import { useLandingContent } from '@/hooks/content';
import { useInactivityModalState } from '@/hooks/use-inactivity-modal-state';
import { useToggle } from '@insurance/hooks';
import { ModalSwitchProvider } from './common/modal-switch-provider';
import { Highlight } from './common/highlight';
import { SectionHero } from './sections/hero';
import { SectionAdvantages } from './sections/advantages';
import { SectionSupport } from './sections/support';
import { SectionFAQ } from './sections/faq';
import { SectionFooter } from './sections/footer';
import { ContactFormModal } from './sections/contact-form-modal';
import s from './style.module.scss';

type TProps = {
  formBaseUrl?: string;
  withFormNextStep?: boolean;
};

export const LumixalLanding: React.FC<TProps> = ({
  formBaseUrl,
  withFormNextStep,
}) => {
  const { title, subtitle, buttonText } = useLandingContent({
    defaultTitle: (
      <>
        <Highlight>Compare</Highlight> quotes.
        <br />
        Rates from $29/month
      </>
    ),
    defaultSubtitle: 'Enter Your ZIP code',
    defaultButtonText: 'View My Quotes',
  });

  const {
    enabled: contactFormModalOpened,
    enable: openContactFormModal,
    disable: closeContactFormModal,
  } = useToggle();

  const { opened: inactivityModalOpened, close: closeInactivityModal } =
    useInactivityModalState();

  useEffect(() => {
    if (inactivityModalOpened) {
      formApi.trackAnalyticsEvent({
        goal: AnalyticsGoals.InactivityDialogOpened,
      });
    }
  }, [inactivityModalOpened]);

  const handleGetQuotes = (values: { zipCode?: string } = {}) => {
    gtag({ event: 'form_start' });

    closeInactivityModal();

    const baseUrlWithParams = formBaseUrl
      ? getUrlWithParams(formBaseUrl, values as Record<string, unknown>)
      : undefined;

    formApi.openForm({
      baseUrl: baseUrlWithParams,
      values,
      withNextStep: withFormNextStep,
    });
  };

  const handleInactivityModalPrimaryAction = () => {
    closeInactivityModal();

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingGoToForm,
      payload: {
        referrer: 'inactivityDialog',
      },
    });

    handleGetQuotes();
  };

  return (
    <main className={cn(outfit.variable, s.global)}>
      <SectionHero
        title={title}
        subtitle={subtitle}
        buttonText={buttonText}
        onGetQuotes={handleGetQuotes}
      />

      <SectionAdvantages />

      <SectionSupport />

      <SectionFAQ />

      <SectionFooter onContactClick={openContactFormModal} />

      <ContactFormModal
        isOpen={contactFormModalOpened}
        onClose={closeContactFormModal}
      />

      <ModalSwitchProvider
        className={cn(outfit.variable, s.global)}
        isShown={inactivityModalOpened}
        onClose={closeInactivityModal}
        onActionClick={handleInactivityModalPrimaryAction}
      />
    </main>
  );
};
