import React from 'react';
import cn from 'classnames';

import s from './style.module.scss';

type TProps = {
  className?: string;
  logoText: string;
};

export const MyAutoArmorLogo: React.FC<TProps> = ({ className, logoText }) => (
  <div className={s.logoContainer}>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className, s.icon, s.desktopLogo)}
    >
      <g filter="url(#filter0_i_770_741)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 10.5719 40 5.85786 37.0711 2.92893C34.1421 0 29.4281 0 20 0V0C10.5719 0 5.85786 0 2.92893 2.92893C0 5.85786 0 10.5719 0 20V20C0 29.4281 0 34.1421 2.92893 37.0711C5.85786 40 10.5719 40 20 40V40C29.4281 40 34.1421 40 37.0711 37.0711C40 34.1421 40 29.4281 40 20V20Z"
          fill="#BFEE2F"
        />
      </g>
      <g filter="url(#filter1_i_770_741)">
        <path
          d="M20.0043 30C19.8043 30 19.6043 29.9565 19.4217 29.8609C19.1086 29.7043 11.7426 25.9561 11.7426 19.9989V13.9113C11.7426 13.3634 12.0817 12.8764 12.5861 12.6938L19.5434 10.0848C19.8391 9.97174 20.1609 9.97174 20.4566 10.0848L27.4138 12.6938C27.9269 12.8851 28.2574 13.3721 28.2574 13.9113V19.9989C28.2574 25.9561 20.8914 29.7043 20.5783 29.8609C20.3957 29.9565 20.1957 30 19.9956 30H20.0043ZM14.3515 14.8157V19.9989C14.3515 23.2601 18.0041 26.0517 19.9956 27.2084C21.5436 26.2952 25.6571 23.5471 25.6571 19.9989V14.8157L20.0043 12.6938L14.3515 14.8157Z"
          fill="#6B802E"
        />
        <path
          d="M20.0043 30C19.8043 30 19.6043 29.9565 19.4217 29.8609C19.1086 29.7043 11.7426 25.9561 11.7426 19.9989V13.9113C11.7426 13.3634 12.0817 12.8764 12.5861 12.6938L19.5434 10.0848C19.8391 9.97174 20.1609 9.97174 20.4566 10.0848L27.4138 12.6938C27.9269 12.8851 28.2574 13.3721 28.2574 13.9113V19.9989C28.2574 25.9561 20.8914 29.7043 20.5783 29.8609C20.3957 29.9565 20.1957 30 19.9956 30H20.0043ZM14.3515 14.8157V19.9989C14.3515 23.2601 18.0041 26.0517 19.9956 27.2084C21.5436 26.2952 25.6571 23.5471 25.6571 19.9989V14.8157L20.0043 12.6938L14.3515 14.8157Z"
          fill="black"
          fillOpacity="0.03"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_770_741"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.843137 0 0 0 0 1 0 0 0 0 0.360784 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_770_741"
          />
        </filter>
        <filter
          id="filter1_i_770_741"
          x="11.7426"
          y="10"
          width="16.5149"
          height="22.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.5" />
          <feGaussianBlur stdDeviation="1.25" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_770_741"
          />
        </filter>
      </defs>
    </svg>

    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className, s.icon, s.mobileLogo)}
    >
      <g filter="url(#filter0_i_770_880)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 10.5719 40 5.85786 37.0711 2.92893C34.1421 0 29.4281 0 20 0V0C10.5719 0 5.85786 0 2.92893 2.92893C0 5.85786 0 10.5719 0 20V20C0 29.4281 0 34.1421 2.92893 37.0711C5.85786 40 10.5719 40 20 40V40C29.4281 40 34.1421 40 37.0711 37.0711C40 34.1421 40 29.4281 40 20V20Z"
          fill="#BFEE2F"
        />
      </g>
      <g filter="url(#filter1_i_770_880)">
        <path
          d="M20.0043 30C19.8043 30 19.6043 29.9565 19.4217 29.8609C19.1086 29.7043 11.7426 25.9561 11.7426 19.9989V13.9113C11.7426 13.3634 12.0817 12.8764 12.5861 12.6938L19.5434 10.0848C19.8391 9.97174 20.1609 9.97174 20.4566 10.0848L27.4138 12.6938C27.9269 12.8851 28.2574 13.3721 28.2574 13.9113V19.9989C28.2574 25.9561 20.8914 29.7043 20.5783 29.8609C20.3957 29.9565 20.1957 30 19.9956 30H20.0043ZM14.3515 14.8157V19.9989C14.3515 23.2601 18.0041 26.0517 19.9956 27.2084C21.5436 26.2952 25.6571 23.5471 25.6571 19.9989V14.8157L20.0043 12.6938L14.3515 14.8157Z"
          fill="#6B802E"
        />
        <path
          d="M20.0043 30C19.8043 30 19.6043 29.9565 19.4217 29.8609C19.1086 29.7043 11.7426 25.9561 11.7426 19.9989V13.9113C11.7426 13.3634 12.0817 12.8764 12.5861 12.6938L19.5434 10.0848C19.8391 9.97174 20.1609 9.97174 20.4566 10.0848L27.4138 12.6938C27.9269 12.8851 28.2574 13.3721 28.2574 13.9113V19.9989C28.2574 25.9561 20.8914 29.7043 20.5783 29.8609C20.3957 29.9565 20.1957 30 19.9956 30H20.0043ZM14.3515 14.8157V19.9989C14.3515 23.2601 18.0041 26.0517 19.9956 27.2084C21.5436 26.2952 25.6571 23.5471 25.6571 19.9989V14.8157L20.0043 12.6938L14.3515 14.8157Z"
          fill="black"
          fillOpacity="0.03"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_770_880"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.843137 0 0 0 0 1 0 0 0 0 0.360784 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_770_880"
          />
        </filter>
        <filter
          id="filter1_i_770_880"
          x="11.7426"
          y="10"
          width="16.5149"
          height="22.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.5" />
          <feGaussianBlur stdDeviation="1.25" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_770_880"
          />
        </filter>
      </defs>
    </svg>

    <span className={cn(s.logoText)}>{logoText}</span>
  </div>
);
