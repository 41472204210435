import React, { type HTMLProps, useRef } from 'react';
import cn from 'classnames';

import { Icon, type TIconName } from '@/components/common/icon';

import s from './style.module.scss';

type TProps = {
  className?: string;
  label?: string;
  icon?: TIconName;
  children?: React.ReactNode;
  isError?: boolean;
  uiSize?: 'md' | 'lg';
  multiline?: boolean;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  errorMessage?: string;
};

export const Field = ({
  className,
  label,
  icon,
  children,
  uiSize,
  onChange,
  multiline,
  errorMessage,
  isError,
  ...props
}: Omit<
  HTMLProps<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>,
  keyof TProps
> &
  TProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const isSelect = children;
  const isInput = !children;

  const onControlFocus = () => {
    if (rootRef.current) {
      rootRef.current.classList.add(s.isFocused);
    }
  };

  const onControlBlur = () => {
    if (rootRef.current) {
      rootRef.current.classList.remove(s.isFocused);
    }
  };

  return (
    <div
      className={cn(
        className,
        s.root,
        s[`size_${uiSize || 'md'}`],
        { [s.isSelect]: isSelect },
        { [s.isInput]: !isInput },
        { [s.isError]: isError },
        { [s.isFilled]: !!props.value },
      )}
      ref={rootRef}
    >
      <div className={s.field}>
        {!children && !multiline && (
          <input
            className={s.control}
            onFocus={onControlFocus}
            onBlur={onControlBlur}
            onChange={onChange}
            {...props}
          />
        )}

        {multiline && (
          <textarea
            className={s.control}
            onFocus={onControlFocus}
            onBlur={onControlBlur}
            onChange={onChange}
            {...props}
          />
        )}

        {children && (
          <select
            className={s.control}
            onFocus={onControlFocus}
            onBlur={onControlBlur}
            onChange={onChange}
            {...props}
          >
            {children}
          </select>
        )}

        {label && <span className={s.label}>{label}</span>}

        {icon && <Icon className={cn(s.icon, s.icon_semantic)} name={icon} />}

        {isSelect && (
          <Icon className={cn(s.icon, s.icon_system)} name="caretDown" />
        )}

        {icon && (
          <Icon
            className={cn(s.icon, s.icon_state, s.icon_positive)}
            name="check"
          />
        )}

        {icon && (
          <Icon
            className={cn(s.icon, s.icon_state, s.icon_negative)}
            name="x"
          />
        )}
      </div>

      {isError && errorMessage && (
        <span className={s.error}>{errorMessage}</span>
      )}
    </div>
  );
};
