import { type FC } from 'react';
import Modal from '@/components/common/modal';
import { ContactForm } from './contact-form';

type TContactFormModal = {
  isOpen: boolean;
  onClose: () => void;
};

export const ContactFormModal: FC<TContactFormModal> = ({
  isOpen,
  onClose,
}) => (
  <Modal
    title="Contact"
    subtitle="Please feel free to contact us with any queries or ideas you have! We are always happy to be of assistance."
    isShown={isOpen}
    onClose={onClose}
  >
    <ContactForm onClose={onClose} />
  </Modal>
);
