import { type FC } from 'react';
import { useMedia } from 'react-use';
import { PhoneCall } from 'phosphor-react';
import { gtag } from '@insurance/utils';
import { AnalyticsGoals } from '@insurance/form-api';
import { formApi } from '@/services/form-api';
import { Section } from '@/components/lumixal-landing/common/section';
import { Container } from '@/components/lumixal-landing/common/container';
import { Highlight } from '@/components/lumixal-landing/common/highlight';
import { Button } from '@/components/lumixal-landing/common/button';
import { useAppConfig } from '@insurance/app-config';
import s from './style.module.scss';

export const SectionSupport: FC = () => {
  const { contactPhone } = useAppConfig();

  const isMobile = useMedia('(max-width: 767px)', false);

  const handleClickCall = () => {
    gtag({ event: 'click_to_call' });

    formApi.trackAnalyticsEvent({
      goal: AnalyticsGoals.LandingClickCall,
      payload: {
        referrer: 'callAgentButton',
      },
    });
  };

  return (
    <Section className={s.root}>
      <Container className={s.container}>
        <div className={s.card}>
          <div className={s.headerSubsection}>
            <div className={s.title}>
              Support from <Highlight>licence agent 24 / 7</Highlight>
            </div>

            <Button
              className={s.button}
              size={isMobile ? 'lg' : 'xl'}
              variant="filled"
              color="neutral"
              IconStart={PhoneCall}
              href={contactPhone.href}
              onClick={handleClickCall}
            >
              Call Agent
            </Button>
          </div>

          <img className={s.img} src="/images/lumixal/support.png" alt="" />
        </div>
      </Container>
    </Section>
  );
};
