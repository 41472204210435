import { type FC, type SetStateAction, type Dispatch } from 'react';
import Modal from '@/components/common/modal';

type TContactSentModalProps = {
  successModal: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
  errorModal: { isOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> };
};

export const ContactSentModal: FC<TContactSentModalProps> = ({
  successModal,
  errorModal,
}) => (
  <>
    <Modal
      title="Message sent!"
      subtitle="Thanks for filling out our form!"
      isShown={successModal.isOpen}
      actions={[
        {
          isPrimary: false,
          label: 'Close',
          variant: 'fill',
          color: 'neutral',
          onClick: () => successModal.setIsOpen(false),
        },
      ]}
    />

    <Modal
      title="Something was wrong."
      subtitle="Please try to send message later."
      isShown={errorModal.isOpen}
      actions={[
        {
          isPrimary: false,
          label: 'Close',
          variant: 'fill',
          color: 'neutral',
          onClick: () => errorModal.setIsOpen(false),
        },
      ]}
    />
  </>
);
