import { useState } from 'react';
import cn from 'classnames';

import { sendContactForm } from '@/api/contact-form';
import { isValidEmail } from '@/utils/email';
import { logger } from '@/utils/logger';
import { Spinner } from '@/components/common/spinner';
import { Button } from '@/components/common/button';
import { Field } from '@/components/lumixal-landing/common/field';

import { ContactSentModal } from '../contact-sent-modal';

import s from './style.module.scss';

export type TContactFormData = {
  name: string;
  email: string;
  message: string;
};

type TContactFormProps = {
  className?: string;
  onClose?: () => void;
};

const CONTACT_FORM_INITIAL_STATE = {
  name: '',
  email: '',
  message: '',
};

export const ContactForm = ({ className, onClose }: TContactFormProps) => {
  const [contactForm, setContactForm] = useState<TContactFormData>(
    CONTACT_FORM_INITIAL_STATE,
  );

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [inPosting, setInPosting] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const updateForm = (type: keyof TContactFormData, value: string) => {
    setContactForm((prevState) => ({ ...prevState, [type]: value }));
  };

  const resetForm = () => {
    setContactForm(CONTACT_FORM_INITIAL_STATE);
    setIsSubmitted(false);
  };

  const isNameInvalid = isSubmitted && !contactForm.name;
  const isEmailInvalid =
    isSubmitted && (!contactForm.email || !isValidEmail(contactForm.email));
  const isMessageInvalid = isSubmitted && !contactForm.message;

  const onSubmitted = async () => {
    setIsSubmitted(true);
    setInPosting(true);

    try {
      const response = await sendContactForm(contactForm);

      if (response.ok) {
        resetForm();
        onClose?.();
        setIsSuccessModalOpen(true);
      } else if (response.status !== 422) {
        onClose?.();
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      logger.error(error, 'Send contact form [Luximal landing] failed');

      setIsErrorModalOpen(true);
    } finally {
      setInPosting(false);
    }
  };

  const isSubmitBtnDisabled =
    isNameInvalid || isEmailInvalid || isMessageInvalid || inPosting;

  return (
    <div className={cn(className, s.root)}>
      {inPosting && (
        <div className={s.overlay}>
          <Spinner />
        </div>
      )}

      <Field
        className={s.field}
        label="Name"
        type="text"
        value={contactForm.name}
        onChange={(event) => updateForm('name', event.target.value)}
        isError={isNameInvalid}
        errorMessage="Is required"
      />

      <Field
        className={s.field}
        label="Email Address"
        type="text"
        value={contactForm.email}
        onChange={(event) => updateForm('email', event.target.value)}
        isError={isEmailInvalid}
        errorMessage="Is required and must be a valid email"
      />

      <Field
        className={s.field}
        label="Message"
        type="text"
        multiline
        rows={4}
        value={contactForm.message}
        onChange={(event) => updateForm('message', event.target.value)}
        isError={isMessageInvalid}
        errorMessage="Is required"
      />

      <ContactSentModal
        successModal={{
          isOpen: isSuccessModalOpen,
          setIsOpen: setIsSuccessModalOpen,
        }}
        errorModal={{
          isOpen: isErrorModalOpen,
          setIsOpen: setIsErrorModalOpen,
        }}
      />

      <Button
        className={cn(s.button, { [s.posting]: inPosting })}
        label="Send Message"
        size="lg"
        iconEnd="arrowRight"
        variant="fill"
        color="neutral"
        onClick={onSubmitted}
        disabled={isSubmitBtnDisabled}
      />
    </div>
  );
};
